import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CompanyService } from '../../../services/companyService';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadApkService } from '../../../services/downloadApkService';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
// export class HeaderComponent implements OnInit, OnDestroy {

//   private destroy$: Subject<void> = new Subject<void>();
//   userPictureOnly: boolean = false;
//   user: any;

//   themes = [
//     {
//       value: 'default',
//       name: 'Light',
//     },
//     {
//       value: 'dark',
//       name: 'Dark',
//     },
//     {
//       value: 'cosmic',
//       name: 'Cosmic',
//     },
//     {
//       value: 'corporate',
//       name: 'Corporate',
//     },
//   ];

//   currentTheme = 'default';

//   userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

//   constructor(private sidebarService: NbSidebarService,
//               private menuService: NbMenuService,
//               private themeService: NbThemeService,
//               private userService: UserData,
//               private layoutService: LayoutService,
//               private breakpointService: NbMediaBreakpointsService) {
//   }

//   ngOnInit() {
//     this.currentTheme = this.themeService.currentTheme;

//     this.userService.getUsers()
//       .pipe(takeUntil(this.destroy$))
//       .subscribe((users: any) => this.user = users.nick);

//     const { xl } = this.breakpointService.getBreakpointsMap();
//     this.themeService.onMediaQueryChange()
//       .pipe(
//         map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
//         takeUntil(this.destroy$),
//       )
//       .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

//     this.themeService.onThemeChange()
//       .pipe(
//         map(({ name }) => name),
//         takeUntil(this.destroy$),
//       )
//       .subscribe(themeName => this.currentTheme = themeName);
//   }

//   ngOnDestroy() {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }

//   changeTheme(themeName: string) {
//     this.themeService.changeTheme(themeName);
//   }

//   toggleSidebar(): boolean {
//     this.sidebarService.toggle(true, 'menu-sidebar');
//     this.layoutService.changeLayoutSize();

//     return false;
//   }

//   navigateHome() {
//     this.menuService.navigateHome();
//     return false;
//   }
// }

export class HeaderComponent implements OnInit, OnDestroy {

  currentTheme = 'default';
  logout: any;
  userPicture = "../../../../assets/images/no-user.png";
  userMenu = [{ title: 'Logout' }];

  constructor(private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService,
    private route: Router, private _location: Location,
    private companyService: CompanyService, private sanitizer: DomSanitizer,
    private downloadApkService : DownloadApkService) {


  }

  roleId
  companyCode
  name
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    //this.userName = sessionStorage.getItem('userName');

    var payload = JSON.parse(sessionStorage.getItem('payload'));
    if(payload!= undefined && payload!= null){
    this.loginId = payload[0].loginId;
    this.roleId = payload[0].roleId;
    this.companyCode = payload[0].companyId;
    this.name = payload[0].loginId;
 
    this.logout = this.menuService.onItemClick()
      .subscribe((event) => {
        if (event.item.title === 'Logout') {
          sessionStorage.clear();
         // console.log("logout");
          this.route.navigate(['/login']);
        }
      });
  

      this.fetchCompany();
    }
      this.changeToMobile();
 
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobile = window.innerWidth <= 768; // Adjust the threshold based on your design
    // You can perform additional actions based on the screen size here
    this.size = this.isMobile ? 'tiny' : 'medium';
  //console.log(" is mobile ", this.isMobile, event);


  }

 

  loginId
  ngOnDestroy() {
    //// console.log("this.logout", this.logout)
    if (this.logout != undefined && this.logout != null) {
      this.logout.unsubscribe();
    }
    // this.logout.unsubscribe();
    // Unsubscribe from the window resize event when the component is destroyed
    window.removeEventListener('resize', this.onResize);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  navigateToTms() {
    window.open("http://tms.mymonthlycharge.com")
  }

  backClicked() {
    this._location.back();
  }

  goToHome() {
    if (this.roleId == 1002) {
      this.route.navigate(['/pages/welcome-page'])
    } else if (this.roleId == 1001 || this.roleId == 1007){
      this.route.navigate(['/pages/dashboard']);
    }
    else{
      // this.route.navigate(['/pages/welcome'])
      this.route.navigate(['/pages/welcome-page'])
    }
  }

  isMobile: boolean;
  size = "medium";
  changeToMobile(){
    this.isMobile = window.innerWidth <= 768; // Adjust the threshold based on your design
    // this.size = this.isMobile ? 'tiny' : 'medium';

    if(this.isMobile){
      // this.size = 'tiny';
      this.userMenu = [{ title: this.name } ,{ title: 'Logout' }];
    }else{
      // this.size= 'medium';
     this.userMenu = [ { title: 'Logout' }];
    }
   
  }

  img
  fetchCompany() {
    this.companyService.searchCompany(this.companyCode).subscribe(
      data => {
        //  //console.log(data);
        var companyLogo = data
        // //console.log("logo ",companyLogo);

        // //console.log(companyLogo);

        let objectURL = 'data:image/jpeg;base64,' + companyLogo[0].image;

        this.img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        //  //console.log(this.img);

      }
    )
  }

  downloadApkFile(){
    this.downloadApkService.downloadAPKFile().subscribe(
      data => {
        this.downloadFile(data, "Payroll.apk")
      }
    )
  }

  downloadFile(data: Blob, name) {//data: response
    const blob = new Blob([data]);//, { type: 'text/csv/jpg/png/doc/spreadsheet' }
    const url = window.URL.createObjectURL(blob); // creates a url for blob file in the new window of browser
    var a = document.createElement("a"); // creates a element in the dom 
    document.body.appendChild(a);
    a.href = url; // added url in the dom element 
    a.download = name; // now after creating the whole element we download it 
    a.hidden = false;
    a.click();
    window.URL.revokeObjectURL(url); // all the memory used during proces  will be released 
    //window.open(url);
  }


}
